<template>
    <div class="goods-analysis">
        <div class="title-panel">
            <div class="item-txt">
                <div class="item-txt-content">
                    带货分析
                </div>
            </div>
        </div>
        <div class="overview-wrapper">
            <div class="overview-item" v-for="(item, key) in goodsData" :key="key">
                <span class="manual-title">{{item.label}}</span>
                <div class="align-items-num">
                    <span class="number-value">{{item.value}}</span>
                </div>
                <div class="overview-label">{{item.tag}}</div>
            </div>
        </div>
        <div class="echarts-wrapper">
            <div class="echarts-item">
                <div id="echarts-online" style="width: 100%; height: 100%;"></div>
            </div>
            <div class="echarts-item">
                <div id="echarts-sale" style="width: 100%; height: 100%;"></div>
            </div>
        </div>
        <div class="category-wrapper">
            <el-table :data="categoryList">
                <el-table-column label="品类">
                    <template slot-scope="scope">
                        <span v-if="scope.row.id.length">{{scope.row.id}}</span>
                        <span v-else>{{scope.row.tag_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="均价" prop="avg_price"></el-table-column>
                <el-table-column label="商品数" prop="product_count"></el-table-column>
                <el-table-column label="销量" prop="volume_text"></el-table-column>
                <el-table-column label="销售额" prop="amount_text"></el-table-column>
                <el-table-column label="平均转化率" prop="avg_convert_rate"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {productAnalysis, productVisual, roughtlyManual} from "@/utils/apis";
import * as echarts from "echarts";

    export default {
        name: "VideoGoodsAnalysisModule",
        data() {
            return {
                goodsData: [
                    {
                        label: "商品数量",
                        value: 0,
                        tag: ""
                    },{
                        label: "直播带货数量",
                        value: 0,
                        tag: ""
                    },{
                        label: "视频带货数量",
                        value: 0,
                        tag: ""
                    },{
                        label: "带货品类",
                        value: 0,
                        tag: ""
                    },{
                        label: "带货品牌",
                        value: 0,
                        tag: ""
                    },
                ],
                goodsList: [],
                categoryList: [],
                echartOnlineIns: null,
                echartSaleIns: null,
                spreadCategoryData: [],
                saleCategoryData: [],
            }
        },
        props: {
            authorId: String,
        },
        mounted() {
            this.manualData()
            this.categoryListfunc()
            this.echartOnlineIns = echarts.init(document.getElementById("echarts-online"))
            this.echartSaleIns = echarts.init(document.getElementById("echarts-sale"))
            this.piePictureData()
            window.onresize = () => {
                this.echartOnlineIns.resize()
                this.echartSaleIns.resize()
            }
        },
        methods:{
            manualData() {
                productAnalysis(this.authorId).then(res => {
                    if (res.code === 200) {
                        let result = res.data;
                        this.goodsData[0].value = result.total_products;
                        this.goodsData[0].tag = result.top_category + " 为主"
                        this.goodsData[1].value = result.total_live_products;
                        this.goodsData[1].tag = "带货直播场次 " + result.total_room_count;
                        this.goodsData[2].value = result.total_aweme_products;
                        this.goodsData[2].tag = "带货视频数 " + result.total_aweme_count;
                        this.goodsData[3].value = result.total_categories;
                        this.goodsData[3].tag = result.top_category + " " + result.top_category_ratio;
                        this.goodsData[4].value = result.total_brands;
                        this.goodsData[4].tag = result.top_brand + " " + result.top_brand_ratio;
                    }
                }).catch(err => {console.log(err)})
            },
            categoryListfunc() {
                productVisual(this.authorId).then(res => {
                    if (res.code === 200) {
                        this.categoryList = res.data
                    }
                }).catch(err => {console.log(err)})
            },
            piePictureData() {
                roughtlyManual(this.authorId).then(res => {
                    if (res.code === 200) {
                        console.log("resdata", res.data)
                        res.data.spread_category && res.data.spread_category.forEach((item) => {
                            let value = {
                                name: item.title,
                                value: item.rate
                            }
                            this.spreadCategoryData.push(value)
                        })
                        this.setOptionsOnline()
                        res.data.best_sale_category && res.data.best_sale_category.forEach((item) => {
                            let value = {
                                name: item.title,
                                value: item.rate,
                            }
                            this.saleCategoryData.push(value)
                        })
                        this.setOptionSale()

                    }
                }).catch(err => {
                    console.log("err", err)
                })
            },
            setOptionsOnline() {
                let option = {
                    title: {
                        text: "上架商品最多品类TOP5",
                        left: '5%',
                    },
                    tooltip: {
                        trigger: 'item',
                        valueFormatter: (value) => value + "%"
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.spreadCategoryData
                        }
                    ]
                }
                option && this.echartOnlineIns.setOption(option)
            },
            setOptionSale() {
                let option = {
                    title: {
                        text: "销量最佳品类TOP5",
                    },
                    tooltip: {
                        trigger: 'item',
                        valueFormatter: (value) => value + "%"
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.saleCategoryData
                        }
                    ]
                }
                option && this.echartSaleIns.setOption(option)
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-analysis {
        .title-panel {
            position: relative;
            padding: 0 20px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:after {
                box-sizing: border-box;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 1px solid hsla(0,0%,48.2%,.1);
            }
            .item-txt {
                height: 100%;
                .item-txt-content {
                    font-size: 16px;
                    font-weight: 500;
                    color: #ff7752;
                    position: relative;
                    height: 100%;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:after {
                        box-sizing: border-box;
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        width: 56px;
                        height: 2px;
                        background: #ff7752;
                        border-radius: 2px;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .overview-wrapper {
            position: relative;
            padding: 30px 20px 0 20px;
            display: flex;
            flex-wrap: wrap;
            &:after {
                pointer-events: none;
                box-sizing: border-box;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 1px solid #F5F5F5;
            }
            .overview-item {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 30px;
                .manual-title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                }
                .align-items-num {
                    margin: 10px 0 5px 0;
                    display: flex;
                    align-items: flex-end;
                    .number-value {
                        font-size: 22px;
                        color: #333;
                        font-weight: 500;
                        line-height: 100%;
                    }
                }
                .overview-label {
                    align-self: flex-start;
                    padding: 2px;
                    background: #fbfbfb;
                    border-radius: 2px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999;
                }
            }

        }
        .category-wrapper {
            margin-top: 20px;
            padding: 0 20px;
        }
        .echarts-wrapper {
          margin-top: 30px;
            width: 100%;
            height: 308px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            .echarts-item {
                flex: 1;
                height: 100%;
            }
        }
    }
</style>